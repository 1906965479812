<template>
  <div>
    <!-- popup подтверждающий удаление -->
    <DeletePopupCommon
      v-if="deleteCkeckboxList.length > 1"
      title="лицевые счета"
      description="Вы уверены, что хотите удалить выбранный список лицевых счетов?"
      :name="numberAccount"
      :dialog="dialog"
      @closeDeletePopup="closeDeletePopup"
      :delete-function="deleteFunction"
    />
    <DeletePopupCommon
      v-else
      title="лицевой счет"
      description="Вы уверены, что хотите удалить выбранный лицевой счет?"
      :name="numberAccount"
      :dialog="dialog"
      @closeDeletePopup="closeDeletePopup"
      :delete-function="deleteFunction"
    />
    <!-- /popup подтверждающий удаление-->
    <TableFilters :title-count="sourceObject.count" :queries="queries" :loading="isDateLoaded">
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
      <template v-if="isAccountsEnabled" v-slot:add-button>
        <router-link
          to="createAccountPage"
          append
          class="btn btn-add btn_color_green"
          >Добавить лицевой счет</router-link
        >
      </template>
      <template v-else v-slot:add-button>
        <v-btn class="btn btn-add btn_color_green" disabled
          >Добавить лицевой счет</v-btn
        >
      </template>
      <template v-if="deleteCkeckboxList.length > 1" v-slot:delete-button>
        <v-btn
          :disabled="isDisabled"
          color="#EB5C6D"
          class="btn btn-delete btn_color_red"
          @click="clickDelete"
          style="color:white"
        >
          Удалить лицевые счета
        </v-btn>
      </template>
      <template v-else v-slot:delete-button>
        <v-btn
          :disabled="isDisabled"
          color="#EB5C6D"
          class="btn btn-delete btn_color_red"
          @click="clickDelete"
          style="color:white"
        >
          Удалить лицевой счет
        </v-btn>
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      disable-router
      :table-data="sourceObject.results"
      :header="header"
      :source-object="sourceObject"
      delete-all
      dropDown
    >
      <template slot-scope="{ itemFather }">
        <v-checkbox
          class="ma-0 pa-0 checkbox-type"
          :value="itemFather.id"
          v-model="deleteCkeckboxList"
          hide-details
        ></v-checkbox>
      </template>
    </TableLayout>
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsAccounts from "@/helpers/tableCols/accounts";
import DeletePopupCommon from "@/components/popup/DeletePopupCommon";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "ObjectsAccounts",
  data() {
    return {
      loadingBtn: false,
      dialog: false, // Диалоговое окно popup удаления
      numberAccount: "", // номер счета
      accountId: "", // id лицевого счета

      isAccountsEnabled: null,

      queries: [
        ...query.accounts.filter(
          el =>
            el.type !== "object__organization__diller" &&
            el.type !== "object__organization" &&
            el.type !== "object"
        )
      ]
    };
  },
  components: { TableFilters, TableLayout, DeletePopupCommon },
  computed: {
    isDisabled() {
      return this.deleteCkeckboxList.length <= 0 ? true : false;
    },
    deleteCkeckboxList: {
      get() {
        return this.$store.getters.getDeleteList;
      },
      set(value) {
        this.$store.commit("UPDATE_DELETE_LIST", value);
        if (this.$store.getters.getCheckedAll.includes("all")) {
          this.$store.commit("SET_CHECKED_ALL", []);
        }
      }
    },
    header() {
      return tableColsAccounts.filter(
        el =>
          el.value != "object_name" &&
          el.value != "organization_name" &&
          el.value != "diller_name"
      );
    },
    sourceObject() {
      return this.$store.getters.getObjectOrganizationAccountsList;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadOrganizationAccountsList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    },

    clickDelete(obj) {
      this.numberAccount = obj.number;
      this.accountId = obj.id;
      this.dialog = true;
    },
    closeDeletePopup(data) {
      this.dialog = data;
    },

    deleteAccount() {
      this.isDateLoaded = true;
      this.$store
        .dispatch("deleteAccount", { id: this.accountId })
        .finally(() => {
          this.$store.dispatch("fetchObjectAccountsList").finally(() => {
            this.isDateLoaded = false;
          });
        });
    },
    deleteFunction() {
      let data = {
        ids: this.deleteCkeckboxList
      };
      this.$store
        .dispatch("deleteArrAccount", { data })
        .then(() => {
          this.isDateLoaded = true;
          this.$store.dispatch("fetchObjectAccountsList").finally(() => {
            this.isDateLoaded = false;
          });
        })
        .then(() => {
          this.$store.dispatch("clearCheckedList");
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.account)
          );
        });
    }
  },
  mounted() {
    this.isAccountsEnabled = this.$store.getters.getObjectInfo.results.accounts_enabled;
  },
  created() {
    this.$store.dispatch("clearCheckedList");
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query, object: this.urlQuery.id }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchObjectAccountsList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query,
            object: this.urlQuery.id
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchObjectAccountsList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  },
  // beforeRouteUpdate(to, from, next) {
  //   if (to.path === from.path) {
  //     this.$store.commit("setSearchQuery", {
  //       query: {
  //         ...to.query
  //         // type: this.type
  //       }
  //     });
  //   this.isDateLoaded = true
  // this.$store.dispatch("fetchPageAccountsList").finally(()=>{this.isDateLoaded = false})
  //   }
  //
  //   next();
  // },

  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearAccountsPage").then(() => next());
  }
};
</script>

<style scoped></style>
